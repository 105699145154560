import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./ClientReviewMobile.css";
import { v4 as uuidv4 } from "uuid";
// import required modules
import { Pagination } from "swiper";
import ReactPlayer from "react-player/lazy";
import axios from "axios";
import ReactPlayerComp from "../../ReactPlayerComp/ReactPlayerComp";

export default function ClientReviewMobile() {
	const pagination = {
		el: ".my-custom-pagination-div",
		clickable: true,
		renderBullet: function (index, className) {
			return '<span class="' + className + '">' + "</span>";
		},
	};
	const [testimonial, setTestimonials] = useState([]);
	useEffect(() => {
		axios
			.get("testimonial.json")
			.then((res) => setTestimonials(res.data))
			.catch((err) => console.log(err));
	}, []);

	return (
		<>
			<Swiper
				pagination={pagination}
				modules={[Pagination]}
				className='mySwiper'>
				{testimonial.map((item, ind) => {
					return (
						<SwiperSlide>
							<div className='col-12 col-sm-4 d-flex align-items-center justify-content-center'>
								<ReactPlayerComp
									url={item.url}
									thumb={item.thumb}
									uid={uuidv4()}
								/>
							</div>
						</SwiperSlide>
					);
				})}
				{/* <SwiperSlide>
					<div className='col-12 col-sm-4 d-flex align-items-center justify-content-center'>
						<ReactPlayer
							light={"assets/thumbnail/Snehlata.jpg"}
							playing={true}
							className='ReactPlayer '
							controls
							playsInline // Disable download button
							config={{
								file: {
									attributes: { controlsList: "nodownload" },
								},
							}}
							// Disable right click
							onContextMenu={(e) => e.preventDefault()}
							width={"100%"}
							height={"100%"}
							playIcon={
								<img
									src='assets/play.png'
									alt=''
									style={{ width: "40px", height: "40px" }}
								/>
							}
							url='https://artisticyoga.com/uploads/videos/red/testimonial/Snehalatha-sen-LP.mp4'
						/>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='col-12 col-sm-4 d-flex align-items-center justify-content-center'>
						<ReactPlayer
							light={"assets/thumbnail/Supreet.jpg"}
							playing={true}
							className='ReactPlayer '
							controls
							playsInline // Disable download button
							config={{
								file: {
									attributes: { controlsList: "nodownload" },
								},
							}}
							// Disable right click
							onContextMenu={(e) => e.preventDefault()}
							width={"100%"}
							height={"100%"}
							playIcon={
								<img
									src='assets/play.png'
									alt=''
									style={{ width: "40px", height: "40px" }}
								/>
							}
							url='https://artisticyoga.com/uploads/videos/red/testimonial/Supreet-kaur-LP-Version.mp4'
						/>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='col-12 col-sm-4 d-flex align-items-center justify-content-center'>
						<ReactPlayer
							light={"assets/thumbnail/review3.png"}
							playing={true}
							className='ReactPlayer '
							controls
							playsInline // Disable download button
							config={{
								file: {
									attributes: { controlsList: "nodownload" },
								},
							}}
							// Disable right click
							onContextMenu={(e) => e.preventDefault()}
							width={"100%"}
							height={"100%"}
							playIcon={
								<img
									src='assets/play.png'
									alt=''
									style={{ width: "40px", height: "40px" }}
								/>
							}
							url='https://artisticyoga.com/uploads/videos/red/testimonial/Salome-LP-version.mp4'
						/>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='col-12 col-sm-4 d-flex align-items-center justify-content-center'>
						<ReactPlayer
							light={"assets/thumbnail/review4.png"}
							playing={true}
							className='ReactPlayer '
							controls
							playsInline // Disable download button
							config={{
								file: {
									attributes: { controlsList: "nodownload" },
								},
							}}
							// Disable right click
							onContextMenu={(e) => e.preventDefault()}
							width={"100%"}
							height={"100%"}
							playIcon={
								<img
									src='assets/play.png'
									alt=''
									style={{ width: "40px", height: "40px" }}
								/>
							}
							url='https://artisticyoga.com/uploads/videos/red/testimonial/Ajay-Kumar-LP-version.mp4'
						/>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='col-12 col-sm-4 d-flex align-items-center justify-content-center'>
						<ReactPlayer
							light={"assets/thumbnail/review5.png"}
							playing={true}
							className='ReactPlayer '
							controls
							playsInline // Disable download button
							config={{
								file: {
									attributes: { controlsList: "nodownload" },
								},
							}}
							// Disable right click
							onContextMenu={(e) => e.preventDefault()}
							width={"100%"}
							height={"100%"}
							playIcon={
								<img
									src='assets/play.png'
									alt=''
									style={{ width: "40px", height: "40px" }}
								/>
							}
							url='https://artisticyoga.com/uploads/videos/red/testimonial/Ravi-Ramasamy-LP-version.mp4'
						/>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='col-12 col-sm-4 d-flex align-items-center justify-content-center'>
						<ReactPlayer
							light={"assets/thumbnail/review6.png"}
							playing={true}
							className='ReactPlayer '
							controls
							playsInline // Disable download button
							config={{
								file: {
									attributes: { controlsList: "nodownload" },
								},
							}}
							// Disable right click
							onContextMenu={(e) => e.preventDefault()}
							width={"100%"}
							height={"100%"}
							playIcon={
								<img
									src='assets/play.png'
									alt=''
									style={{ width: "40px", height: "40px" }}
								/>
							}
							url='https://artisticyoga.com/uploads/videos/red/testimonial/ATHREYA-RAMADAS-LP-version.mp4'
						/>
					</div>
				</SwiperSlide> */}
			</Swiper>
			<div class='my-custom-pagination-div text-center' />
		</>
	);
}
