import { getAnalytics, logEvent } from 'firebase/analytics';
import React,{useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import ThankYouPopUp from '../ThankYouPopUp/ThankYouPopUp'

const ThankyouPage = () => {

    const history = useHistory();

const [modalShow, setModalShow] = React.useState(false);
const [ShowThanksPopUp, setShowThanksPopUp] = React.useState(true);

const ThankYouPopUpHide = () => setShowThanksPopUp(false);
const ThankYouPopUpShow = () => setShowThanksPopUp(true);

const analytics = getAnalytics();

const userDetails = localStorage.getItem("UserDetails")


useEffect(() => {
    // setTimeout(() => {
    //     history.push("/")
    // }, 5000);

    logEvent(analytics, 'Conversion Event Studio Page',JSON.parse(userDetails))

}, [])


  return (
    <ThankYouPopUp ThankYouPopUpHide={ThankYouPopUpHide} ShowThanksPopUp={ShowThanksPopUp} />
  )
}

export default ThankyouPage