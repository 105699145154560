import React from "react";
import Footer from "../Footer/Footer";
import JoinNowModal from "../JoinNowModal/JoinNowModal";
import Nav from "../Nav/Nav";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

export default function TermsConditionPage() {
    const [modalShow, setModalShow] = React.useState(false);

    return (

        <>
            <JoinNowModal  show={modalShow} onHide={() => setModalShow(false)} />
            <div id='home'>
                <Nav modalHandler={setModalShow} />
                <TermsAndConditions />
                <Footer />
            </div>
        </>
    );
}
