import React, { useState , useEffect, useRef} from "react";
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from './Components/HomePage/HomePage';
import PrivacyPage from "./Components/PrivacyPage/PrivacyPage";
import TermsConditionPage from "./Components/TermsConditionPage/TermsConditionPage";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ThankyouPage from "./Components/ThankyouPage/ThankyouPage";
import TagManager from 'react-gtm-module'
// import h337 from "heatmap.js";

// const firebaseConfig = {
// 	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
// 	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
// 	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
// 	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
// 	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
// 	appId: process.env.REACT_APP_FIREBASE_APP_ID,
// 	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };


const firebaseConfig = {
	apiKey: "AIzaSyDONZbzl71C-m0TYJfYUHJIixQexFQKzxw",
	authDomain: "ay-individual-promo-page.firebaseapp.com",
	projectId: "ay-individual-promo-page",
	storageBucket: "ay-individual-promo-page.appspot.com",
	messagingSenderId: "577654701967",
	appId: "1:577654701967:web:38a07edccf82f8e56155fa",
	measurementId: "G-2N5CMNRYPB"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);



const tagManagerArgs = {
	gtmId: 'GTM-WJNKG7HZ'
  }
  TagManager.initialize(tagManagerArgs)


function App() {

	window.dataLayer.push({
		event: 'pageview'
	});

// 	const [coords,setCoords] = useState([])
// 	const [time,setTime] =  useState(1)

// 	// function mouseStopped(){
		
// 	// 	// console.log(timer)
// 	// }


// const mouseXY = async e =>{
// 	// setTime(0)
// 	// clearTimeout(timer);
//   //   timer=setTimeout(mouseStopped,50);
//     setCoords((prevState) => [...prevState, { x: e.clientX, y: e.clientY,val:time }]);
// }



// useEffect(()=>{
//     window.addEventListener('mousemove', mouseXY);
// },[time,coords])

// const heatMapCont = document.getElementById(".heatMapContainer")

// const elementRef = useRef(null);


// const handleStopEvent = () => {
//   window.removeEventListener('mousemove', mouseXY);

//   if(coords){

//      var heatmapInstance = h337.create({
//       // only container is required, the rest will be defaults
//       container: document.querySelector('.heatMapContainer')
//     });
//     // now generate some random data
   
//     var max = 5;
//     var width = elementRef.current.clientWidth;
//     var height = elementRef.current.clientHeight;
//     var len = 200;


//    let pp = coords.filter( (ele, ind) => ind === coords.findIndex( elem => elem.x === ele.x && elem.y === ele.y))
//    console.log(pp)
//     var data = {
//       max: max,
//       data: pp
//     };
//     // if you have a set of datapoints always use setData instead of addData
//     // for data initialization
//     heatmapInstance.setData(data);
//   }
// }

  return (
	<div className="heatMapContainer" id="heatMapContainer">
    {/* <button onClick={handleStopEvent}>Show HeatMap</button> */}
      <Switch>
        <Route exact path='/'><HomePage /></Route>
        <Route exact path='/ThankyouPage'><ThankyouPage /></Route>
        <Route exact path='/privacy-policy'><PrivacyPage /></Route>
		    <Route exact path='/terms-conditions'><TermsConditionPage /></Route>
      </Switch>
	  </div>
  );
}

export default App;
