import React from 'react'
import "./termsAndConditions.css"

const TermsAndConditions = () => {
  return (
    <div className='TermsAndConditions'>
      <div className="header">
        <h1>TERMS AND CONDITIONS</h1>
      </div>

      <p>Artistic Yoga (“Mobile Application” and “Website” together referred to as “App”) is 
developed and owned by Bharat Thakur Artistic Yoga Centre / Bharat Thakur Artistic Yoga/ 
Bharat Thakur Yoga & Fitness/ BTA Yoga Center LLC (“Company”) a company having its 
registered office at 411, Detroit House Building, Motor City.</p>

      <p>
      These Terms and Conditions (hereinafter referred to as the “Terms”) shall govern the use of the 
App by any user (“you”/ “your”/ Customer”) accessing or using the App operated by the 
Company. All Customers are advised to read and understand these Terms and the Privacy Policy 
prior to accessing or using the App.

      </p>

      <p>
      These Terms and the Privacy Policy are electronic documents and does not require any 
signatures (physical or digital) and by clicking on the acceptance button or by selecting the 
relevant checkbox, you shall be bound by these Terms and Privacy Policy. By using the App, 
you signify that you have read, understood and agreed to be bound by these Terms and Privacy 
Policy. The Company may change these Terms and Privacy Policy at any time without notice. 
Your continued use of the App shall be construed as your acceptance to the revised Terms and 
Privacy Policy.
      </p>

      <p>
      Eligibility: Any person accessing or using this App must have attained the prescribed minimum 
age to enter into a contract and should be competent to enter into a contract under applicable 
laws. You hereby represent that you are of legal age to form a binding contract and are not 
barred from receiving the Services (defined herein below) under the applicable laws.
      </p>

    <div className="terms">
      <h3>1. DEFINITIONS</h3>

      <p>1.1. “Confidential Information” shall mean any and all information which is by its nature 
confidential, whether or not designated as confidential or commercially sensitive by the 
Company and which is not in the public domain including without limitation, operating 
manuals, development, utility, general or specific data, research, functionality, performance, 
source code, know-how, trade secret, Intellectual Property Rights, licenses, information 
pertaining to the Services and other related technical and non-technical information and any 
and all information pertaining to the Company’s App.</p>

    <p>
    1.2. “Intellectual Property” shall mean and include collectively or individually, the following 
worldwide rights relating to the intangible property, whether or not filed or registered:
    </p>

    <p>
    1.2.1. Patents, patent disclosures, patent rights, know-how, including any and all 
continuations, continuations-in-part, divisions, reissues, re-examinations, utility,
    </p>

    <p>
    1.2.2. Rights associated with works of authorship, including without limitation, copyrights, 
copyright applications, copyright registrations;
    </p>

    <p>
    1.2.3. Rights in trademarks, trademark registrations, and applications, trade names, service 
marks, service names, logos, or trade dress;
    </p>

    <p>
    1.2.4. Rights relating to the protection of trade secrets and Confidential Information;
    </p>

    <p>
    1.2.5. Privacy and Publicity rights;
    </p>


    <p>
    1.2.6. Internet domain names, internet and world wide web URLs or addresses; and
    </p>

    <p>
    1.2.7. All other intellectual or proprietary rights anywhere in the world including rights of 
privacy and publicity, whether or not requiring registration and whether or not such 
registration has been obtained.
    </p>

    <p>
    1.3. “Services” shall mean and include the yoga and meditation sessions and other services 
provided through the App operated by the Company.

    </p>
    </div>



    <div className="terms">
      <h3>2. INTERPRETATIONS</h3>

      <p>
      2.1. The Customer and the Company shall together be referred to as the “parties” and in their 
individual capacity as a “party”.
      </p>

      <p>
      2.2. Any reference to the “Privacy Policy” under these Terms shall mean the document that 
enumerates the collection, processing, retention and purging of the personal data of the 
Customer by Company.
      </p>

      <p>
      2.3. The words “include”, “including”, “inter alia” and “in particular” shall be construed as being 
by way of illustration or emphasis only and shall not be construed as, nor shall they take 
effect as, limiting the generality of any preceding words.
      </p>


      <p>
      2.4. The terms ‘hereof’, ’herein’, ’hereby’, ’hereto’ and derivative or similar words, unless 
followed by a reference to a specific provision of these Terms refer to these Terms in its 
entirety.
      </p>


      <p>
      2.5. The headings given hereunder in these Terms are for the purpose of convenience and do not 
affect the interpretation of these Terms.

      </p>

      <p>
      2.6. Unless the contrary is expressly stated, no clause in these Terms limits the extent or 
application of another clause.
      </p>

    </div>



    <div className="terms">
      <h3>
      3. REGISTRATION
      </h3>

      <p>
      3.1. You herein agree and accept that to access the Services provided on the App, you are 
required to register on the App. In furtherance of the same, you are required to disclose 
certain information including but not limited to name, age, gender, height, weight, health 
profile, email address, phone number and password.

      </p>

      <p>
      3.2. You shall ensure that the information provided for registration is accurate, updated and 
complete and that such information is updated from time to time.
      </p>

      <p>
      3.3. You shall be solely responsible for maintaining the confidentiality of the password. 
Company shall not be held responsible for any loss that you may incur as a result of any 
unauthorized access of the App via your account by a third party.
      </p>

      <p>
      3.4. You agree that you will not permit, enable or facilitate any third party to use your account. 
In the event the Company becomes aware of any such unauthorized access or disclosure, the 
Company shall retain the right to suspend or terminate your access to the Services with 
immediate effect without any prior notice or refund of any kind.
      </p>

      <p>
      3.5. In the event you believe that the account is no longer secure, you must immediately notify 
the same to the Company via the contact details provided under these Terms.. The Company 
shall not be liable for any loss, damages or injuries that a user may sustain as a result of 
accessing and using the account of another user or customer.

      </p>
    </div>



    <div className="terms">
      <h3>4. HEALTH AND MEDICAL CONCERNS</h3>

      <p>
      4.1. The Service includes information and instruction relating to physical activities and fitness. 
You acknowledge and agree that the following warnings and disclaimers shall/may apply to 
all such information, instruction and Services.
      </p>

      <p>
      4.2. Before participating in any sessions or Services that may be described and/or made 
accessible in or through the App, it is recommended that you consult with a medical 
practitioner. The Company, its staff and the content-providers are not licensed medical care 
providers or rendering personal medical advice or treatment and have no expertise in 
advising on, diagnosing, examining, or treating medical conditions of any kind or in 
determining the effect of any specific exercise on a medical condition.
      </p>

      <p>
      4.3. The Services are not intended to be a substitute for professional medical advice, diagnosis, 
or treatment. You acknowledge and agree that when participating in any session provided 
on the App, there is the possibility of physical injury and/or death, and you assume the risk 
and responsibility for any such results.
      </p>

      <p>
      4.4. If you know or suspect that you may be pregnant, have any physical or medical condition, it 
is imperative that you seek the advice of your doctor prior to using the Services provided on 
the App.
      </p>


      <p>
      4.5. The Company shall not be held responsible in any manner for any injuries, loss or damages 
sustained by you as a result of using the Services provided on the App.

      </p>

    </div>



    <div className="terms">
      <h3>5. SUBSCRIPTION PLANS</h3>

      <p>
      5.1. The Company offers several subscriptions plans as described below:
      <ul>
       <li>1 day subscription</li> 
       <li>1 month subscription</li> 
       <li>3 months subscription</li> 
       <li>6 months subscription</li> 
       <li>Annual subscription</li> 
       <li>Recurring subscription</li> 

      </ul>
The company can add any other subscription plans to enhance the users experience.
      </p>
    </div>



    <div className="terms">
      <h3>6. CHANGES TO SUBSCRIPTION PLANS</h3>
      <p>6.1. The Company herein has the right to modify, suspend or discontinue the subscription plans at 
    any time.. Any such change in the subscription plans shall be duly notified to you. Kindly note 
    that although such changes shall not affect the existing accounts, the same shall be
    reflected, if applicable, in your account at the time of renewal of your subscription plans. 
    The Company shall not be liable for any loss that you may incur as a result of such change.
    </p>

      <p>
      6.2. You shall have an option to upgrade your subscription plan at any time by contacting us 
vide the contact details provided under these Terms. In the event you wish to downgrade 
the subscription plan, kindly note that there will be no refund for the amount already paid.
      </p>
    </div>



    <div className="terms">
      <h3>7. ACCESS AND USE OF THE APP</h3>
      <p>7.1. The Company hereby grants you a non-exclusive, revocable and limited right to use and 
access the App and avail the Services. You agree and accept to use the App only for the 
purpose permitted under these Terms.</p>

      <p>
      7.2. The Company shall reserve the right at any time to reasonably modify, or discontinue, 
permanently or temporarily, the Services and/or the App or any part thereof. Company 
shall not be held liable for any reasons whatsoever for any loss or damage that may arise 
due to such modification, suspension or discontinuance of the Services and/or the App.
      </p>
    </div>


    <div className="terms">
      <h3>8. PAYMENT TERMS AND CONDITION</h3>

      <p>
      8.1. To access the Services on the App, you are requested to make the required payment. Kindly 
note that payment once made are non-refundable, non-extendable, non-freezable and/or 
non-revivable for any reason whatsoever, including but not limited to, accident, death, 
medical injury, or unsatisfactory experience of the Services.
      </p>

      <p>
      8.2. Kindly note that the Company works with third-party payment processing partners to offer 
the most convenient payment method available in your country and to keep your payment 
information secure. If the payment method fails and you still get access to the Services, you 
agree to pay the Company the due amount within seven (7) days from the date of 
notification from the Company. The Company reserves the right to disable access to the 
Services on the App for which adequate payment has not been received. You agree that in 
the event the Company is unable to collect the amount owed for the Services, the Company 
may take the steps it deems necessary to collect such amount from you and you herein shall 
be responsible for all costs and expenses incurred by the Company in connection with such 
collection activity.

      </p>
    </div>


    <div className="terms">

      <h3>9. RENEWAL</h3>

      <p>9.1. The recurring subscription billing period is on the date the Company receives the payment. 
The Company shall automatically renew your subscription and you irrevocably authorize 
the Company to make the required payment on your behalf via our third-party payment 
processing partners. This authorization shall remain in full force and effect unless you have 
disabled the automatic renewal of the subscription period in your account settings or 
otherwise notify the Company via the contact details provided under these Terms, that you 
wish to revoke the authorization or cancel your subscription.
</p>
    </div>


    <div className="terms">
      <h3>10. REFERRAL POLICY</h3>

      <p>10.1. A referral program may be initiated by the Company to encourage you (“Referee”) to refer 
    the App to users who have not subscribed to the Services on the App before (“Referred”).
    </p>

    <p>
    10.2. The Referee shall be provided with a referral code, which the Referee may choose to share 
with users who have not subscribed to the Services on the App and have consented to 
receive such referral code.
    </p>

    <p>
    10.3. The Referred Customer shall receive the credits upon entering the referral code shared by 
the Referee while subscribing to the Services on the App. The Referee shall receive the 
credits upon the Referred entering the code while subscribing to the Services on the App.
    </p>

    <p>
    10.4. In the event of termination of your account in accordance with these Terms, any credits 
earned through this referral policy, if any, shall be forfeited.
    </p>

    <p>
    10.5. The sole decision made by the Company in the event of any issue pertaining to refer or be 
referred shall be final and binding.

    </p>

    <p>
    10.6. The Company reserves the right to terminate, suspend or modify the referral program at 
any time, without notice to the Customers. However, such changes shall not affect the 
benefits already availed by a Referee and Referred in accordance with this Agreement.

    </p>

    </div>


    <div className="terms">
      <h3>11. RESTRICTIONS ON USE</h3>
      <h4>11.1. You are hereby restricted from carrying out the following activities in any manner:</h4>

      <p>
      11.1.1. Use the App in any manner or for any purpose that is contrary to or in violation of 
the permissible use or is in violation of any applicable law or is against the 
established principles of morality.

      </p>

      <p>
      11.1.2. Modify or alter the whole or any part of the App or render them incapable of full 
and effective use.
      </p>

      <p>
      11.1.3. Alter or remove any of the proprietary markings or content from the App including 
but not limited to any logos, trademarks, information, other proprietary marks or 
any content present on the App
      </p>

      <p>
      11.1.4. Sharing, copying, duplicating, selling, trading or offering for sale the whole or any 
part of the App or any content available on the same.
      </p>

      <p>
      11.1.5. Blocking or restricting any other person’s access to the App.
      </p>

      <p>
      11.1.6. Use any of the communication features or services for any unlawful or otherwise 
inappropriate use.
      </p>

      <p>
      11.1.7. Stimulate or attempt to stimulate or duplicate any of the appearance, features or 
functionality of the App.
      </p>

      <p>
      11.1.8. Impersonating any other person.

      </p>

      <p>
      11.1.9. Use any of Company’s information or content for the benefit of any competing 
business.
      </p>

      <p>
      11.1.10. Do any act that may harm or injure Company, any person, entity or other Customer.
      </p>

      <p>
      11.1.11. Falsifying any information or intentionally misleading or attempting to cheat 
Company.
      </p>

      <p>
      11.1.12. Modify, hack or attempt to gain unauthorized access to the App.
      </p>

      <p>
      11.1.13. Attempt to alter the features, functions or functionality of the App in any manner.
      </p>

      <p>
      11.1.14. Alter, reverse engineer, decompile or otherwise disseminate any of the software 
forming a part of the App.
      </p>

      <h4>
      11.2. You shall not post, publish, upload, transmit, make public or distribute any information, 
material or content that is or may be construed as:
      </h4>

      <p>
      11.2.1. Hurtful, inciting, harmful, discriminating, defamatory, unlawful, threatening, 
fraudulent or misleading.
      </p>

      <p>
      11.2.2. Pornographic, obscene, vulgar, inappropriate, explicit or paedophilic.
      </p>

      <p>
      11.2.3. Any content containing any viruses, trojans, logic bombs, keystroke loggers or 
other harmful, disruptive or malicious programming or code.
      </p>

    </div>


    <div className="terms">
      <h3>12. TERM AND TERMINATION</h3>

      <p>
      12.1. This Agreement shall come into force and shall be binding upon your access or use of the 
App and shall continue to be in force till the end of your subscription period or until 
terminated by either you or the Company.

      </p>

      <p>
      12.2. You may terminate or cancel your subscription plan by contacting us vide the details 
provided under this Terms. Kindly note that there will be no refund provided in the event 
you wish to discontinue your account.

      </p>

      <p>
      12.3. The Company reserves the right, at their sole discretion and with immediate effect, to 
terminate your account and/or block or prevent access and use of the Services provided on 
the App in the event of any of the following:

      </p>

      <p>
      12.3.1. If you terminate or cancel your subscription plan in accordance with this Terms.
      </p>

      <p>
      12.3.2. Failure or default to observe any of the Customer obligations, irrespective of 
whether such breach constitutes a material breach or breaches any or all terms set 
forth under these Terms.
      </p>

      <p>
      12.3.3. If you violate or the Company has reasonable grounds to believe that you may be in 
violation of any applicable law, rules or regulations.
      </p>

      <p>
      12.3.4. If in the opinion of Company, your action or omissions have prejudicially affected 
the reputation of Company in any manner whatsoever.

      </p>

      <p>
      12.4. Payment obligations shall survive post termination of this Agreement.
      </p>

      <p>
      12.5. Any and all rights granted by the Company to the you under these Terms shall cease, 
effective immediately, on the termination of your account.
      </p>
    </div>


    <div className="terms">
      <h3>13. THIRD-PARTY SERVICES</h3>

      <p>
      13.1. The App, from time to time, may contain some links to third party websites and/or services 
that are not owned or controlled by the Company. The Customer shall be bound by the terms
and conditions and privacy policy of such third-parties and Company shall in no manner 
whatsoever be held liable for these policies.
      </p>
    </div>


    <div className="terms">
      <h3>14. CONFIDENTIAL INFORMATION</h3>

      <p>
      14.1. You hereby agree that at all times during the course of the engagement with the Company 
and thereafter (Without limit of time):

      </p>

      <p>
      14.1.1. to hold the Confidential Information in the strictest confidence in the manner it would 
extend the protection of such confidential information having its proprietary rights, and 
not to use or attempt to use the same, except for the benefit of the Company;
      </p>

      <p>
      14.1.2. not to disclose or divulge the Confidential Information to any person or entity 
without the written authorization of the Company;
      </p>

      <p>
      14.2. However, you shall not be liable for any breach of Confidential Information if:
      </p>

      <p>
      14.2.1. Such information already existed in the public domain prior to its engagement with 
the Company.
      </p>

      <p>
      14.2.2. You can prove that such information was known to it prior to their engagement with 
the Company.
      </p>


      <p>
      14.2.3. The Confidential Information was made available in the public domain due to no 
fault of yours.
      </p>

      <p>
      14.2.4. Such Confidential Information was disclosed in compliance with any judgment or 
order of a competent court or in accordance with any law for the time being in force 
or due to an order by any governmental authority.
      </p>

    </div>


    <div className="terms">
      <h3>15. INTELLECTUAL PROPERTY</h3>

      <p>
      15.1. The Company shall own all the Intellectual Property rights subsisting or used in connection 
with the App, including but not limited to any patents, trademarks, logos, icons, domain 
names or copyright.
      </p>

      <p>
      15.2. You understand and accept that the Company in no manner is granting you any Intellectual 
Property rights in the App nor constitute a transfer of any rights, titles or interests of the 
Intellectual Property belonging to the Company.
      </p>

      <p>
      15.3. No content, material, name including the logo of Company shall be copied or otherwise 
published (through paper or in any electronic form) by you.
      </p>

      <p>
      15.4. You agree and accept to use the App solely for the purposes permitted under these Terms. 
You hereby understand that you are prohibited from marketing, selling, replicating, reverse 
engineering, decompiling or attempting to do any of the aforementioned of any of the 
Intellectual Property of the Company.

      </p>

      <p>
      15.5. You understand and accept that any breach of the aforementioned clauses shall be considered 
as a material breach of these Terms and the Company shall have the right to terminate this 
engagement with immediate effect or seek such other legal remedies available under the law.

      </p>

      <p>
      15.6. Your obligation detailed under this clause shall survive the termination of your engagement 
with the Company.

      </p>

    </div>


    <div className="terms">
      <h3>
      16. REPRESENTATIONS AND WARRANTIES
      </h3>

      <p>
      16.1. You hereby represent and warrant that:
      </p>

      <p>
      16.1.1. You are competent to enter into this engagement and discharge all the obligations 
set forth in accordance with these Terms.
      </p>

      <p>
      16.1.2. You have full authority to enter into this engagement and to execute, deliver and 
perform all or any of its obligations under these Terms.
      </p>

      <p>
      16.1.3. The information provided by you to access and use the App and/or the Services is 
true and updated.
      </p>

      <p>
      16.1.4. You have not been previously suspended or removed from the App or from availing 
the Services for any reason whatsoever.
      </p>

      <p>
      16.1.5. The use of the App and/or the Service is in compliance with any and all applicable 
laws and regulations.
      </p>
    </div>

    <div className="terms">
      <h3>17. INDEMNITY</h3>

      <p>
      17.1. You hereby agree and accept to indemnify the Company from and against all losses, claims, 
costs, liabilities or expenses incurred or sustained by the Company or for any suit that may be 
initiated by a third party whether based in claims of tort or contract (including court costs and 
attorney fees) that may be incurred or sustained as a consequence of the following:

      </p>

      <p>
      17.1.1. Use of the App in a manner not expressly permitted under these Terms
      </p>

      <p>
      17.1.2. Willful misconduct
      </p>

      <p>
      17.1.3. Negligence, fraud, misrepresentation, or breach of its obligations under these Terms
      </p>

      <p>
      17.1.4. Breach of any of its representations and warranties under these Terms
      </p>

      <p>
      17.1.5. Violation of any of the applicable laws.
      </p>

      <p>
      17.2. Under no circumstances shall the Company be liable for any loss of actual or anticipated 
revenues, profits, goodwill, an opportunity of business or for any consequential, special, 
incidental, exemplary, punitive or other indirect losses or damages, whether arising out of 
or related to these Terms, including those in contract, tort (including negligence) even if 
such loss was foreseeable and the Company had been advised of the possibility of such 
damages or loss.
      </p>
    </div>


    <div className="terms">
      <h3>18. DISCLAIMERS OF WARRANTY AND LIABILITY</h3>

      <p>
      18.1. The Company makes no express warranties to the Customer regarding the App. The App is 
being provided to the Customer “as is” and “as available” without warranty of any kind. 
The Company makes no warranty that the App will be error-free or available at all times, 
nor does the Company warrant the compatibility or operation of the App with all the 
hardware or software configurations.
      </p>

      <p>
      18.2. The Company disclaims all warranties with regard to the App, express or implied, 
including without limitation, any implied warranties of fitness for a particular purpose, 
merchantability, merchantable quality, or non-infringement of third-party rights.

      </p>

      <p>
      18.3. The Company cannot guarantee and does not promise any specific results from the Services.
      </p>

      <p>
      18.4. By using the Services on the App, you hereby accept full responsibility for your own safety 
and understand that in the absence of an instructor physically present to advise, it is your 
responsibility to ensure that you stay within your physical limits.

      </p>

      <p>
      18.5. The Company shall not be liable in any manner whatsoever for any injury or loss 
(including death) sustained as a result of your participation in the sessions or from availing 
any features or other Services available on the App.

      </p>

      <p>
      18.6. No oral or written information or advice given by the Company, its distributors, agents or 
employees shall create a warranty or in any way increase the scope of any warranty 
provided herein.
      </p>

    </div>


    <div className="terms">
      <h3>19. COMMUNICATIONS</h3>

      <p>
      19.1. The Company herein shall have the right to communicate certain communications 
including but limited for the purpose of service announcements, administrative changes and 
such other communications.
      </p>
    </div>


    <div className="terms">
      <h3>20. PROMOTIONS</h3>
      <p>
      20.1. The Company herein reserves the right to offer promotional plans to customers for a period 
and price determined by the Company, at its sole discretion. You herein understand and 
accept that unless the promotional offer is advertised as available to existing customers, 
you shall not be entitled to such promotional plans.

      </p>
    </div>


    <div className="terms">
      <h3>21. AMENDMENTS OR MODIFICATIONS</h3>
      <p>21.1. Any amendment or modification to these Terms shall be duly notified to the Customer via 
a notification on the App. By continuing to use our App and/or the Services after any such 
changes, the Customer herein accepts these Terms as modified.
</p>
    </div>


    <div className="terms">
      <h3>22. WAIVER AND SEVERABILITY</h3>
      <p>
      22.1. Any failure or delay to exercise a right or remedy shall not be construed as a waiver by 
either of the parties unless such a waiver is expressly set forth in writing.
      </p>
      <p>
      22.2. Any provision of these Terms that is declared null, void or unenforceable shall be ineffective 
only to that extent and the remaining provisions shall be binding on both the parties.
      </p>
    </div>


    <div className="terms">
      <h3>23. FORCE MAJEURE</h3>
      <p>
      23.1. For the purposes of these Terms, Force Majeure shall mean without limitation, any event, 
beyond the control of the parties which prevents either party from fulfilling any of the 
obligations under these Terms, including but not limited to Act of God, (including fires, 
earthquakes, floods or other natural calamities), epidemics, change in law, war, hostilities, 
invasion, rebellion, strikes, lockouts, nuclear explosions, revolution or riots. Neither party
shall be responsible for any breach or non-performance of these Terms if such breach or 
non-performance was the result of a Force Majeure event.

      </p>
    </div>


    <div className="terms">
      <h3>24. ARBITRATION, GOVERNING LAW AND JURISDICTION</h3>

      <p>
      24.1. In the event of any disputes or claim arising with respect to these Terms, the parties shall 
first attempt to resolve the same amicably through discussions.
      </p>

      <p>
      24.2. In the event that the parties are unable to solve the disputes or claims in an amicable 
manner, the parties shall attempt to resolve the same through arbitration in accordance with 
the laws of UAE as amended from time to time. The arbitrator shall be appointed by 
Company and the Customer hereby consents to the same. The seat for such arbitration shall 
be in UAE and all arbitration proceedings shall be in English.
      </p>

      <p>
      24.3. These Terms shall be governed by and construed in accordance with all applicable laws of 
UAE and the courts at UAE shall have exclusive jurisdiction over any matter arising out of 
these Terms.
      </p>
    </div>


    <div className="terms">
      <h3>25. PRIVACY POLICY</h3>
      <p>25.1. The Privacy Policy governs the manner in which your personal information may be dealt 
with by the Company. The Privacy Policy is an essential document and has to be read and 
understood along with this Agreement.
</p>
    </div>

    <div className="terms">
      <h3>26. ENTIRE AGREEMENT
</h3>

    <p>
    26.1. These Terms, Privacy Policy and any other additional terms implemented by the Company 
from time to time in writing represents the complete agreement entered into between the 
Customer and the Company and supersedes all prior agreements, negotiations, 
representations and discussions whether made orally or in writing affected by the parties in 
that regard.
    </p>
    </div>


    <div className="terms">
      <h3>27. CONTACT US</h3>

      <p>
      27.1. In case of any complaint, dispute or queries regarding these Terms, please contact Company
through the information provided below. The Company shall undertake all reasonable efforts to 
address the Customer’s queries or grievances at the earliest opportunity.

      </p>

      <p>
      Address: 411, Detroit House Building, Motor City.
      </p>

      <p>
      Email: info@artisticyoga.com
      </p>

      <p>
      Telephone: 800-9642
      </p>
    </div>

    </div>
  )
}

export default TermsAndConditions