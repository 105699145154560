import React from 'react'
import "./privacyPolicy.css"

const PrivacyPolicy = () => {
  return (
    <div className='PrivacyPolicy'>
        <div className="header">
            <h1>Privacy Policy</h1>
        </div>

        <div className="aboutApp">
            <p>Artistic Yoga (“Mobile Application” and “Website” together referred to as “App”) is 
        developed and owned by Bharat Thakur Artistic Yoga Centre / Bharat Thakur Artistic Yoga/ 
        Bharat Thakur Yoga & Fitness/ BTA Yoga Center LLC (“Company”/ “we”/ “our”/ “us”) a 
        company having its registered office at 411, Detroit House Building, Motor City.
        We understand that when you (“you”/“your”) use the App, you instill certain trust in us. We 
        aspire to keep the trust intact. Our Privacy Policy (“Policy”) is designed to help you understand 
        what Data we collect, why we collect such Data, how we store and use the Data and what rights 
        do you have with respect to the Data collected.
            </p>
        </div>


        <div className="policy">
            <h3>1. DEFINITIONS AND INTERPRETATIONS</h3>
            <p>Any and all terms not defined under this Policy shall have the same meaning as provided under
the Terms of the App.</p>

        <p>1.1 “Consent” shall mean your acceptance is free from any undue influence or ambiguity with
regard to the terms of this Policy and Terms of the App and you consent to the collection,
storage, use and disclosure of the Data as described in the terms of this Policy.</p>

        <p>1.2 “Data” shall mean the information collected by us, whether or not by automated means,
through the App which directly or indirectly relates to you and may be subjected to Processing
in accordance with this Policy. </p>

        <p>
        1.3 “Process/Processing” shall mean operation or set of operation which is performed on the Data
or on sets of Data, such as collection, recording, organization, structuring, storage, adaptation,
alteration, retrieval, use, alignment or combination, indexing, disclosure by transmission,
dissemination or otherwise making available, restriction, erasure or destruction of the Data.
        </p>
        </div>



        <div className="policy">
            <h3>2. DATA COLLECTED</h3>
            <p>We collect the following Data to enable us to provide the Services effectively: </p>

            <h4>2.1 INFORMATION PROVIDED TO US </h4>
            <p>2.1.1 We collect certain personally identifiable Data such as name, email address, phone
number, user-name and password, gender, height, weight and health profile for the
purpose of registering with our App. </p>
            
            <h4>2.2 INFORMATION COLLECTED BY US</h4>
            <p>2.2.1 When you use the App we may automatically collect information such as a record of
            the details of usage, any comments, opinions, feedback and other communications with
            us or directed at us which you provide through the App, technical information about
            your device for system administration and analysis, including your IP address, unique
            device identifiers, software version, service provider, operating system, network and
            browser type, information about how you use the App, and such other information that </p>

            <p>2.2.2 Kindly note if your device is operated on iOS 13 or later, the information is collected
by Apple in accordance with their policies and shared with us.</p>


            <h4>2.3 INFORMATION WE COLLECT FROM THIRD PARTIES</h4>
            <p>
            2.3.1 From time to time, we may receive Data about you from third party sources including
but not limited to the partners with whom we engage in joint marketing activities and
sources such as social media websites.
            </p>
        </div>


        <div className="policy">
            <h3>3. CONSENT</h3>
            <p>3.1 By using our Services, you hereby expressly consent to the collection, Processing, storing,
        disclosing and handling of the Data enumerated under clause 2 in accordance with the terms
        of this Policy. </p>
        </div>


        <div className="policy">
            <h3>4. USE AND PURPOSE OF THE DATA COLLECTED</h3>
            <p>4.1 We use the Data we collect to manage and to provide the core functionality of the Services
            through the App. The Data we acquire from and/or about you shall be treated as confidential
            and shall be used only for the purpose of enabling us to provide the Services effectively.
            </p>

            <p>4.2 We may use the Data collected to send you general commercial communications about our
            Services that may interest you, newsletters with features updates and other information that
            may enhance your experience with our Services.
            </p>
            
            <p>4.3 We may use the Data that you provide vide emails or any communication made to us to provide
            customer support and/ or to respond to your queries and send emails about the maintenance of
            the App and/or App or any updates.</p>
        </div>



        <div className="policy">
            <h3>5. DISCLOSURE OF DATA </h3>
            <p>Except as provided herein in this Policy, no personally identifiable Data will be disclosed or shared
            with any third- parties other than those explicitly provided, without your express Consent.</p>

        <h4>5.1 AUTHORIZED REPRESENTATIVES</h4>

        <p>5.1.1 We may disclose the Data collected to any of our authorized agents, employees,
        officers, representatives or consultants, strictly on confidential terms, as reasonably
        necessary, for the purposes set out in this Policy. </p>

        <p>
            5.1.2 We may disclose the Data to such parties in order to provide you with customer support
    facilities, for the purpose of operating and maintaining the App, to comply with our
    legal obligations and/or to enforce our Terms of Service and the App.
        </p>


        <p>
            5.1.3 The Data collected may also be disclosed or otherwise transferred to an acquirer, or
    successor or assignee as part of any merger, acquisition, debt financing, sale of assets,
    or similar transaction, as well as in the event of insolvency, bankruptcy, or receivership
    under which information is transferred to one or more third parties as one of our
    business assets.
        </p>

        <h4>5.2 PROMOTIONAL PURPOSE</h4>

        <p>5.2.1 We may disclose the Data collected for marketing purposes including but not limited
    to the purpose of contacting you to further discuss your interest in the Services and to
    3
    send you information regarding the Company, its affiliates, and its partners, such as
    information about promotions or events. </p>

    <h4>5.3 COMPELLED DISCLOSURE </h4>

    <p>5.3.1 We may disclose the Data as may be legally required in the event of any requirement
by court order, government or to any extent required by law or legal proceedings to the
appropriate authority in order to comply with the rules and regulations.</p>

    <p>5.3.2 We shall neither assume any liability nor indemnify you for any loss, damages or
expenses incurred by you due to the compulsory disclosure of the Data by us. </p>

        </div>

        
        <div className="policy">
            <h3>6. DATA SECURITY</h3>

            <p>6.1 We have implemented and will maintain physical, electronic and procedural security measures
in accordance with the standards prescribed under the applicable law that are reasonably
designed to help protect the integrity and the security of the Data. </p>

            <p>6.2 Although we will do our best to protect your personal data, no security system can prevent all
potential security breaches and therefore, we cannot guarantee the security of your data
transmitted to the App and you acknowledge that any transmission is at your own risk. Once
we have received your information, we will use strict procedures and security features that are
appropriate to the type of personal data you have provided to try to prevent unauthorized
Processing such as unauthorized access, disclosure, alteration or removal of Data. </p>

            <p>6.3 In the event the Company becomes aware of any security breach that affects any personal data
held or stored by the Company in connection with the Services, the Company shall adopt
adequate efforts to communicate the details of the same to you, without unreasonable delay. </p>

        </div>


        <div className="policy">

            <h3>7. DATA RETENTION</h3>

            <p>7.1 We shall retain the Data for such period necessary to fulfill the purposes we collected the 
Data for or for a period required under the applicable law, unless instructed otherwise by 
you, in writing, subsequent to which the same shall be deleted from our internal records, 
systems and databases.</p>

            <p>7.2 Notwithstanding anything contained hereinbefore, the Data collected by Apple shall be 
retained for a period of one (1) year from the date of collection.</p>

            <p>7.3 Any encrypted, anonymized and/ or aggregated Data may be retained by us for the purposes 
of backup, archiving, prevention of fraud and abuse, analytics, to improve our Services, App 
or for such other reasons that we deem necessary to retain the same.</p>

            <p>7.4 Notwithstanding anything contained in clause 7, we shall retain and use the Data collected as 
necessary to resolve disputes, enforce our agreements and comply with legal obligations for 
such a period thereafter as required under any law for the time being in force.</p>

        </div>

        <div className="policy">
            <h3>8. DATA TRANSFER</h3>

            <p>8.1 Our Service is hosted in UAE but is made available to you worldwide. If you choose to access or 
use the App outside UAE, please be aware that Data we collect will be subject to the applicable 
laws governing data protection. Please be aware that Data we may collect will be transferred to, 
processed, stored and used in UAE. The data protection laws in UAE may differ from those of 
the country in which you are located and your Data may be subject to orders from the 
government, courts or law enforcement in UAE according to the applicable laws.</p>


        </div>

        <div className="policy">
            <h3>9. RIGHTS</h3>

            <h4>9.1 Subject to the laws applicable, you shall have the rights as stipulated hereunder:</h4>

            <p>9.1.1 A right to confirm whether the Data is processed and a right to access in one place the 
identities of the parties with whom the Data has been shared.</p>

            <p>9.1.2 A right to correction and erasure of the Data collected by us which is no longer 
necessary for the purpose for which it was Processed.</p>

            <p>9.1.3 A right to data portability in the event of processing through automated means.</p>


            <p>9.1.4 A right to restrict or prevent the continuing disclosure of the Data where such 
            disclosure has served the purpose for which it was collected and is no longer 
            necessary for the purpose or if such disclosure was made contrary to any applicable 
            law for the time being in force.
            </p>   

            <p>9.2 You may exercise any of the rights mentioned hereinbefore via the contact details provided 
herein under this Policy.</p>  

            <p>9.3 If you no longer wish to receive any commercial email from us, you may opt-out of receiving 
        them by following the instructions provided in such communications or by sending your 
        request to us by email provided under the contact details of this Policy and we endeavor to 
        take the appropriate action within ten (10) business days from the date of raising such 
        request. Please note, however, that you may continue to receive service-related 
        communications even after you opt-out of receiving commercial messages.
        </p>
        </div>



        <div className="policy">
            <h3>10. ALERTS AND UPDATES</h3>

            <p>10.1 We reserve the right to contact you for the purpose of verification of the Data provided by 
you.</p>

            <p>10.2 We may also alert you by notification on the App about any new feature or any other such 
information which we perceive might be useful for you.
</p>
        </div>


        <div className="policy">
            <h3>11. CHILD PRIVACY</h3>

            <p>11.1 The App is not directed at or intended to be used by children below the prescribed age limit 
as per the applicable laws and we do not knowingly collect any Data from such an 
individual. If you believe we have collected any Data of a person below the prescribed age 
limit, you can contact us via the contact details provided herein under this Policy and such 
Data shall be removed accordingly.
</p>
        </div>


        <div className="policy">
            <h3>12. LINKS</h3>

            <p>12.1 Our App, from time to time, may contain some links to and from the websites of our partner 
        networks and affiliates. If you follow a link to any of these websites, please note that these 
        websites have their own privacy policies and that we do not accept any responsibility or 
        liability for these policies. Please check these policies before you submit any personal data 
        to these websites.
            </p>
        </div>

        <div className="policy">
            <h3>13. AMENDMENTS</h3>

            <p>13.1 We adopt adequate efforts to notify you in the event of any amendment or modification to 
            the terms of this Privacy Policy via email or a notification. By continuing to use our 
            Services after we post any such changes to this Policy, you accept the Policy as modified.
            </p>
        </div>



        <div className="policy">
            <h3>14. APPLICABLE LAW</h3>

            <p>14.1 We have complied with all the applicable laws with respect to the collection, use, sharing, 
and retention of your Data.
            </p>

            <p>14.2 In the event any conflict arises between the terms of this Policy and provisions of any 
        applicable laws, then such provisions of applicable laws shall prevail over the terms of this 
        Policy.</p>

        <p>14.3 For any queries with respect to the same, you can contact us via the contact details provided 
            herein under this Policy.
            </p>
        </div>



        <div className="policy">
            <h3>15. ARBITRATION, GOVERNING LAW AND JURISDICTION</h3>
            <p>15.1 In the event of a dispute or claim arising with regard to this Policy you shall first attempt to 
resolve the same amicably through discussions.</p>

            <p>15.2 In the event that the parties are unable to solve the disputes or claims in an amicable 
        manner, the parties shall attempt to resolve the same through arbitration in accordance with 
        the laws of UAE as amended from time to time. The arbitrator shall be appointed by us and 
        you hereby consent to the same. The seat for such arbitration shall be UAE and all 
        arbitration proceedings shall be in English.</p>

        <p>15.3 This Policy shall be governed by and construed in accordance with all applicable laws of 
        UAE and the courts at UAE shall have exclusive jurisdiction over any matter arising out of 
        this Policy.
        </p>
        </div>



        <div className="policy">
            <h3>16. CONTACT US</h3>
            <p>16.1 If you have any complaint, dispute or queries regarding this Policy or our means of Processing 
your Data, please contact us through the information given below. We endeavor to undertake all 
reasonable efforts to address your queries or grievance at the earliest opportunity</p>

            <p>Address: 411, Detroit House Building, Motor City.</p>
            <p>Email: info@artisticyoga.com</p>
            <p>Telephone: 800-9642</p>
        </div>

    </div>
  )
}

export default PrivacyPolicy