import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import PI from "react-phone-input-2";
import useGeoLocation from "react-ipgeolocation";
import { useState } from 'react';
import "./ebookModal.css"
import "react-phone-input-2/lib/style.css";

import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import { logEvent, getAnalytics } from 'firebase/analytics';

const EbookModal = ({setEbookPopUp}) => {

    const analytics = getAnalytics()

    const ReactPhoneInput = PI.default ? PI.default : PI;

    const location = useGeoLocation();

    const [error, setError] = useState('')
	const [isEmailOk, setIsEmailOk] = useState("")
    const [isDisable, setisDisable] = useState(true);

    const [btnText, setbtntext] = React.useState("Get your E-book");

    const [countrycode, setCountryCode] = useState(undefined);
    const [number, setNumber] = useState(undefined);

    const [phoneNo,setPhoneNo] = useState(null)

    const handleChange = (value, data, event, formattedValue) => {
        const mobile = value.slice(data.dialCode.length)
        setPhoneNo(mobile)
        setEbookDownload((prev) => ({ ...prev, mobile, isd: data.dialCode}))

    };

    const [EbookDownload, setEbookDownload] = React.useState({
        name: "",
        emailId: "",
        mobile: "",
        isd: '',
    });

    function download_file(fileURL, fileName) {
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement("a");
            save.href = fileURL;
            save.target = "_blank";
            var filename = fileURL && fileURL.substring(fileURL.lastIndexOf("/") + 1);
            save.download = fileName || filename;
            if (
                navigator.userAgent
                    .toLowerCase()
                    .match(/(ipad|iphone|safari)/) &&
                navigator.userAgent.search("Chrome") < 0
            ) {

                document.location = save.href;
            } else {
                var evt = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }
        }
        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(fileURL, "_blank");
            _window.document.close();
            _window.document.execCommand("SaveAs", true, fileName || fileURL);
            _window.close();
        }
    }
    const downloadEffect = () => {

        logEvent(analytics,"Download E-Book user data: Studio Page", EbookDownload)

        if (EbookDownload.name && EbookDownload.emailId && EbookDownload.mobile && EbookDownload.isd) {

            const submitFormData = {
				...EbookDownload,originCampaign:"E-Book Download: Studio Page"
			}
			const NewFormData =  JSON.stringify(submitFormData)
            const privyrEbookData = JSON.stringify({...submitFormData,email:EbookDownload.emailId,phone:phoneNo,lead_source: "E-book Studio Page"})

            console.log(NewFormData)
            axios
                .post("https://qrs.btartisticyoga.com/magicFood/getEBookByMail/",
                   NewFormData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    
                    console.log(res.data);
                    // if (res.data !== "failed") {
                    //     if (res.data.status_text != "200") {
                    //         setError(res.data.status_text)
                    //     }

                    // }
                    if (res.data != null) {
                        // return(
                        //     <a href={res.data.ebookURL} target='_blank' download={"Ebook.pdf"}></a>
                        // )
                        download_file(res.data.ebookURL, "FreeRed.pdf");
                        // document.getElementById("Name").setAttribute("readOnly", "true")
                        // document.getElementById("Email").setAttribute("readOnly", "true")
                        // document.getElementById("Mobile").setAttribute("readOnly", "true")
                       
                    }
                })
                .catch((err) => console.log(err));
        }


    };

    useEffect(() => {
      if(EbookDownload.name && EbookDownload.emailId && EbookDownload.mobile.length >= 5)
      {
        setisDisable(false)
      }
      else{
        setisDisable(true)
      }
    }, [EbookDownload.name && EbookDownload.emailId && EbookDownload.mobile])
    

  return (
    <div className='EbookPopUp'>
        <Modal
		className='EbookModal'
		show={true}
		// size='md'
		backdrop='static'
		keyboard={false}
		aria-labelledby='contained-modal-title-vcenter'
		centered>
			<Modal.Body className="p-0">
            <div className='congratulations pt-4'>
			<div className='header'>
				<div className='px-5 d-flex align-items-center justify-content-center position-relative'>
					<h1 className='title'>Get your free E-book Now!</h1>
					<p
						onClick={() => {
							setEbookPopUp(false);
						}}
						className='position-absolute'
						style={{ right: 0, top: 0, paddingRight: "24px" }}>
						<img
							src='assets/close.svg'
							alt=''
							className='img-fluid'
						/>
					</p>
				</div>
				{/* <div className='px-4 brief d-flex align-items-center '>
                    Get a free E-book Now!
				</div> */}

			</div>
			<div className='px-4 '>
				<div className='d-flex flex-column mt-3'>
					<label htmlFor='Name'>Name</label>
					<input
						// onChange={handleFormData}
						placeholder='Enter your name'
						type='text'
						id='Name'
						name='name'
                        onChange={(e) => setEbookDownload((prev) => {
                            return { ...prev, name: e.target.value }
                        })}
					/>
				</div>
				<div className='d-flex flex-column mt-3'>
					<label htmlFor='Email'>Email Address</label>
					<input
						 onChange={(e) => setEbookDownload((prev) => {
                            return { ...prev, emailId: e.target.value }
                        })}
						name='emailId'
						placeholder='Enter your email'
						type='email'
						id='Email'
					/>
				</div>
			
				<div className='d-flex flex-column mt-3'>
					<label htmlFor="email">Phone No</label>


					<PhoneInput
						// @ts-ignore
						className="joinNowinput"
						onChange={handleChange}
						placeholder='Enter your mobile'
						type='text'
						id='Mobile'
						country={location?.country?.toLowerCase()}
					/>

				</div>
				<div className='d-flex align-items-center justify-content-center  '>

                    <button
						disabled={isDisable}
						onClick={downloadEffect}
                        style={isDisable ? {background:"linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%)",opacity:"0.2"} : {background:"linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%)"}}
						className='proceedBtn downloadEbookBtn disableDownloadEbookBtn'>
						Download E-book
					</button>

				</div>
				{/* {error && <div className="warning">
					<div className="d-flex justify-content-center align-items-center ">
						<img src="assets/Warning.png" alt="" />
						<p>{error}</p>
					</div>

				</div>} */}
			</div>
		</div>
			</Modal.Body>
		</Modal>
    </div>
  )
}

export default EbookModal