import React from "react";
import ReactPlayer from "react-player";
import JoinAdd from "../JoinAdd/JoinAdd";
import ReactPlayerComp from "../ReactPlayerComp/ReactPlayerComp";
import "./RedBanner.css";
import { v4 as uuidv4 } from "uuid";


export default function RedBanner({ modalHandler }) {
	const handleWhatsapp = () => {
		window.open("https://wa.me/971585386009?text=Hi%20", "_blank");
	};
	return (
		<div className='banner text-center'>
			
		
			<div className='stats py-4'>
				<div className='stateBox'>
					<div className='logo'>
						<img
							src='assets/heart.svg'
							alt=''
							className='img-fluid d-none d-sm-inline-block'
						/>
						<img
							src='assets/heartMobile.svg'
							alt=''
							className='img-fluid d-sm-none d-inline-block'
						/>
					</div>
					<div className='number'>1,672,000</div>
					<div className='brief'>Lives Transformed</div>
				</div>
				<div className='stateBox'>
					<div className='logo'>
						<img
							src='assets/meter.svg'
							alt=''
							className='img-fluid d-none d-sm-inline-block'
						/>
						<img
							src='assets/meterMobile.svg'
							alt=''
							className='img-fluid d-sm-none d-inline-block'
						/>
					</div>
					<div className='number'>6,688,000</div>
					<div className='brief'>Kgs Lost</div>
				</div>
				<div className='stateBox'>
					<div className='logo'>
						<img
							src='assets/clock.svg'
							alt=''
							className='img-fluid d-none d-sm-inline-block'
						/>
						<img
							src='assets/timerMobile.svg'
							alt=''
							className='img-fluid d-sm-none d-inline-block'
						/>
					</div>
					<div className='number'>13,260,000</div>
					<div className='brief'>Hours of Yoga</div>
				</div>
			</div>

			
			<div className="redBannerHeader">
				<div className="redBannerContainer">
					<h2>
					Life is to be enjoyed. You don’t need any special diet or restrictions to get fitter and be happier!
					</h2>
				</div>
				
			<div className='featureBrief row row-cols-1 row-cols-xs-2  row-cols-md-1 row-cols-lg-2'>

			<div className='bannerVideo mt-0 mt-sm-0 mt-md-5 mt-lg-0'>
					<ReactPlayerComp
						url={
							"https://firebasestorage.googleapis.com/v0/b/ay-individual-promo-page.appspot.com/o/1%20min%20studio%20video%201440x1080%20(1).mp4?alt=media"
						}
						thumb='https://btay-videos.s3.ap-southeast-1.amazonaws.com/thumbnail/Thumb/studiopromo/Promo+thumbnail.jpg'
						uid={uuidv4()}
					/>

					{/* <video 
						
						controls
						controlsList="nodownload"
							poster=
				"https://btay-videos.s3.ap-southeast-1.amazonaws.com/thumbnail/Thumb/studiopromo/Promo+thumbnail.jpg">
						<source src=
				"https://firebasestorage.googleapis.com/v0/b/ay-individual-promo-page.appspot.com/o/1%20min%20studio%20video%201440x1080%20(1).mp4?alt=media"
								type="video/mp4"/>
										
					</video> */}
				</div>

				<div className='pt-4 pb-4 pb-sm-4 pb-md-0 d-flex flex-column justify-content-end px-3 px-sm-5 align-items-center'>
					<div className='row row-cols-2 gy-4  mb-2 bannerIcons' >
						<div className='d-flex flex-column justify-content-center align-items-center'>
							<img
								width="48px"
								src='assets/calander.svg'
								className='img-fluid d-none d-sm-inline-block'
								alt=''
							/>
							<img
								width="32px"
								src='assets/calanderMobile.svg'
								className='img-fluid d-sm-none d-inline-block'
								alt=''
							/>
							<span className='briefIdea'>6 Days a Week</span>
						</div>
						<div className='d-flex flex-column justify-content-center align-items-center'>
							<img
								width="48px"
								src='assets/studio.svg'
								className='img-fluid d-none d-sm-inline-block'
								alt=''
							/>
							<img
								width="32px"
								src='assets/studio.svg'
								className='img-fluid d-sm-none d-inline-block'
								alt=''
							/>
							<span className='briefIdea'>In Studio</span>
						</div>
						<div className='d-flex flex-column justify-content-center align-items-center BannnerSectionTimer' >
							<img
								width="48px"
								src='assets/timer.svg'
								className='img-fluid  d-none d-sm-inline-block'
								alt=''
							/>
							<img
								width="32px"
								src='assets/timerMobile.svg'
								className='img-fluid  d-sm-none d-inline-block'
								alt=''
							/>
							<span className='briefIdea'>1 Hour Daily</span>
						</div>
						<div className='d-flex flex-column justify-content-center align-items-center BannnerSectionMeditation'>
							<img
								width="48px"
								src='assets/meditation.svg'
								className='img-fluid d-none d-sm-inline-block'
								alt=''
							/>
							<img
								width="32px"
								src='assets/meditationMobile.svg'
								className='img-fluid d-sm-none d-inline-block'
								alt=''
							/>
							<span className='briefIdea'>Ongoing Classes</span>
						</div>
					</div>
					<div className='mt-4 px-4 px-sm-0 position-relative'>
						<JoinAdd modalHandler={modalHandler} />
						<div onClick={handleWhatsapp} className="ChatOptionMobile d-flex d-sm-flex d-md-none">
						<img width={22} height={20} src="assets/whatsappIos.svg" alt=""/><p className="ms-1">Chat with us</p> 
						</div>
					</div>
				</div>
				{/* <p className="mb-3 sm-mb-3 d-block d-sm-block d-md-none FounderMessage">A Message from Our Founder</p> */}
				
			</div>
		</div>

		</div>
	);
}
