// @ts-nocheck
import React, { useEffect, useState } from "react";
// import Countdown, { zeroPad } from "react-countdown";
import JoinAdd from "../JoinAdd/JoinAdd";
import "./Advertisement.css";
import moment from "moment/moment";
import axios from "axios";
export default function Advertisement({ type, modalHandler }) {


	const [offerDate,setOfferDate] = useState(null)


	useEffect(() => {
	  
		axios.get("https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/offerDates")
		.then((res) => {
			console.log("Offer Dates",res)
			const studioDate = res.data.filter((item) => {
				return item?.docId == "studio"
			})
			console.log(studioDate)
			setOfferDate(studioDate[0]?.offerDate?._seconds)
			// setOfferDate(res.data[0])
		})
		.catch((err) => {
			console.log(err)
			
		})

	}, [])
	



	const handleDate = () => {
		
		
		const currentDate = moment()
		const isoFormat = moment(offerDate * 1000).format()
		// console.log(moment(isoFormat).isBefore(currentDate))


		if(offerDate && moment(isoFormat).isAfter(currentDate)){
			return moment(offerDate * 1000).format('Do MMM')
		}
		else if(offerDate && moment(isoFormat).isBefore(currentDate)){
			return moment().endOf('month').format('Do MMM');
		}
		
		else if(offerDate && moment(offerDate * 1000).format('Do MMM') !== moment().endOf('month').format('Do MMM') ){
			return moment(offerDate * 1000).format('Do MMM')
		}
		return moment().endOf('month').format('Do MMM');
	}

	const ad1 = () => {
		return (
			<>
				<div>
					<h2 className='title'>
						No Two Classes are the{" "}
						<span className='pink'>SAME!</span>
					</h2>
					<p className='brief my-3'>
						Every Session Surprises The Body, Activates New Muscle
						And is Uniquely Designed to Give Maximum Results.
					</p>
				</div>
				
				<div className='joinAddBtn'>
					<JoinAdd modalHandler={modalHandler} />
				</div>
			</>
		);
	};
	const ad2 = () => {
		return (
			<>
				<div>
					<h2 className='title'>
						Just Burning Calories is{" "}
						<span className='pink'>Not Enough</span>
					</h2>
					<p className='brief my-3'>
					With Artistic Yoga, you improve flexibility, gain strength, harmonize your internal organs and breathe correctly, literally recharging your whole system.
					</p>
				</div>
				
				<div className='joinAddBtn'>
					<JoinAdd modalHandler={modalHandler} />
				</div>
			</>
		);
	};
	const ad3 = () => {
		return (
			<>
				<div className='w-100'>
					<h2 className='title'>
						Our Teachers are A{" "}
						<span className='pink'>Breed Apart</span>
					</h2>
					<p className='brief my-3'>
					Our teachers are personally trained by Dr. Bharat Thakur for years in the deepest aspects of yoga as well as a complete understanding of the human body and its various systems.
					</p>
				</div>
				
				<div className='joinAddBtn'>
					<JoinAdd modalHandler={modalHandler} />
				</div>
			</>
		);
	};

	const ad4 = () => {
		return (
			<>
				<div className='w-100'>
					<h2 className='title'>
						Not able to attend our{" "}<br/>
						<span className='pink'>Studio classes?</span>
					</h2>
					<p className='brief my-3'>
					We also have Online Zoom sessions and personal one on one sessions at your home (in Dubai only).
					</p>
				</div>
				
				<div className='joinAddBtn'>
					<JoinAdd modalHandler={modalHandler} />
				</div>
			</>
		);
	};
	return (
		<div className='advertisementWrapper px-3'>
			<div className='Advertisement p-sm-5 p-sm-2 p-4'>
				<div className='row row-cols-1 row-cols-sm-2 g-3'>
					<div className='col-12 col-sm-7 d-flex align-items-center'>
						<div className='w-100 h-100 d-flex flex-column justify-content-center'>
							{type == 1 && ad1()}
							{type == 2 && ad2()}
							{type == 3 && ad3()}
							{type == 4 && ad4()}
						</div>
					</div>
					<div className=' col-12 col-sm-5 d-flex flex-column align-items-center justify-content-center'>
						<div className='w-100'>
							<div className='specialOfferBanner'>

								<div className="specialOfferImg d-none d-sm-inline-block">
									<img
										src='assets/offersvg1.svg'
										alt=''
										className='img-fluid d-none d-sm-inline-block'
										style={{width:"auto"}} 
									/>
									<p>Till {handleDate()}</p>
								</div>
								
								<div className="resp-specialOfferImg specialOfferImg d-sm-none d-inline-block">
									<img
										src='assets/offersvg1.svg'
										alt=''
										width= "162px"
										// height=" 101px"
										className='img-fluid  d-sm-none d-inline-block'
									/>
									<p>Till {handleDate()}</p>
								</div>
							</div>
							
						</div>
						{/* <div className="OfferNeverBefore d-md-none d-sm-block joinAd flex-column">
							<div >
								Never Before Offer
							</div>
						</div> */}
						<div className='mt-4 d-sm-none d-block  my-sm-4'>
							<JoinAdd modalHandler={modalHandler} />
						</div>

						{/* <div className='d-sm-none d-block'>
							<div className='remain d-flex align-items-center justify-content-center py-4'>
								Remaining Until
							</div>
							
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
}
