import React from "react";
import "./Benefits.css";
export default function Benefits() {
	return (
		<div className='benifits '>
			<h1 className='benifitsHeader d-flex align-items-center justify-content-center'>
				One Hour, Multiple Benefits
			</h1>
			<div className='d-flex flex-column flex-md-row flex-wrap '>
				<div className='col-12 col-sm-12 col-md-12 col-lg-4 benifitBox'>
					<div>
						<div className='d-flex align-items-center justify-content-center'>
							<img
								src='assets/medit.svg'
								alt=''
								className='img-fluid'
							/>
						</div>
						<div className='title text-center my-2'>
							All The Benefits of Traditional{" "}
							<br className='d-none d-sm-block' /> Yoga
						</div>
						<div className='description'>
							Higher Energy Levels, Feeling of Lightness, Hormonal
							balance, Improved immunity, Better Flexibility
						</div>
					</div>
				</div>
				<div className='col-12 col-sm-12 col-md-12 col-lg-4 benifitBox'>
					<div>
						<div className='d-flex align-items-center justify-content-center'>
							<img
								src='assets/dumble.svg'
								alt=''
								className='img-fluid'
							/>
						</div>
						<div className='title text-center my-2'>
							All The Benefits of Physical{" "}
							<br className='d-none d-sm-block' /> Workout
						</div>
						<div className='description'>
							Weight Loss, Inch Loss & Fat Loss, Improved
							muscle-tone, Increased stamina & cardiovascular
							endurance, High overall physical fitness
						</div>
					</div>
				</div>
				<div className='col-12 col-sm-12 col-md-12 col-lg-4 benifitBox'>
					<div>
						<div className='d-flex align-items-center justify-content-center'>
							<img
								src='assets/lotus.svg'
								alt=''
								className='img-fluid'
							/>
						</div>
						<div className='title text-center my-2'>
							All The Benefits of{" "}
							<br className='d-none d-sm-block' /> Meditation
						</div>
						<div className='description'>
							Mental clarity, Emotional stability, Improved sleep,
							Reduction in stress levels
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
