import React from 'react'
import { useState } from 'react'
import StudiosBatches from "../studioBatches.json"


const MotorCityLocation = () => {
    const [toggleTab,setToggleTab] = useState(0)

    const [program,setProgram] = useState("Red")
    const handleToggle = (index,prog) => {
        setToggleTab(index)
        setProgram(prog)
    }

    

  return (
    <div className='BurCityLocation'>

        <div className="burcityLocationImg">
                <div className="locationNameResponsive">
                    <p>Motor City</p>
                    <div className="currentLocation">
                        <img src="/assets/locationMarker.svg" alt="" />
                        <span>Office no 411 , Detroit House</span>
                    </div>
                </div>
            <div className="locationImg">
                <img src="/assets/locations/motorCity.png" alt="" />
            </div>
            <div className="locationMap">
                <div className="locationName">
                    <p>Motor City</p>
                    <div className="currentLocation">
                        <img src="/assets/locationMarker.svg" alt="" />
                        <span>Office no 411 , Detroit House</span>
                    </div>
                </div>
                <div className="mapLocation">
                <iframe style={{border:"0px"}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1807.3256401214926!2d55.229829!3d25.0459058!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6e71529988bd%3A0xcc8c58e2608821d1!2sBharat%20Thakur&#39;s%20Artistic%20Yoga%20-%20Motor%20City!5e0!3m2!1sen!2sin!4v1671287842063!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>


        <div className="contactDetails d-flex justify-content-end" style={{marginTop:"10px"}}>
                <div className="callDetails">
                    <img src="/assets/callIcon.png" alt="" />
                    <p>800-YOGA(9642)</p>
                </div>

                <div className="callDetails" style={{marginLeft:"40px"}}>
                <a
                      rel='noreferrer'
                      target='_blank'
                      href={`https://wa.me/971585386009?text=Hi%20I%20am%20interested%20in%20joining%20the%20${program}%20program.%20Can%20you%20please%20share%20more%20details.%20Thanks%20`}
                      // {user!==null || undefined ? user?.firstName.split(" ")[0]:""}
                     >
                    <img src="assets/whatsappIcon.png" alt="" />
                    <p className='BookNow' style={{textDecoration:"underline"}}>Book Now</p>
                </a>
                </div>
            </div>

        <div className="divider"></div>

        <div className="TabsContainer">
        <div className="ClassTabsContainer">
            <div className={toggleTab == 0 ? "classTabs activeClassTab" : "classTabs"} onClick={()=>handleToggle(0,"Red")}>
                RED
            </div>
            <div className={toggleTab == 1 ? "classTabs activeClassTab" : "classTabs"} onClick={()=>handleToggle(1,"AY Classic")}>
                AY CLASSIC
            </div>
            {/* <div className={toggleTab == 2 ? "classTabs activeClassTab" : "classTabs"} onClick={()=>handleToggle(2)}>
                MEDITATION
            </div> */}
            {/* <div className={toggleTab == 3 ? "classTabs activeClassTab" : "classTabs"} onClick={()=>handleToggle(3)}>
                MORE
            </div> */}
        </div>
        

        {/* ================RED Time======================== */}
        {toggleTab == 0 && <div className="ClassTabContent">
            <h3 style={{marginBottom:"5px"}}>AM (Morning)</h3>
            <span className='duration'>Duration - 1 Hour</span>

            <div className="normalTime">
            {
                StudiosBatches.MotorCity.map((item)=>(
                    item.id == "Red" &&
                    item.NormalBatch.map((value)=>(
                        value?.batch == "morning" ?
                        <span>{value.time}</span> : <></>
                    ))         
                ))
            }

            </div>

            <div className="ladiesTime">
                {
                    StudiosBatches.MotorCity.map((item)=>(
                        item.id == "Red" &&
                    item.LadiesBatch && item.LadiesBatch.map((value)=>(
                            value?.batch == "morning" ?
                            <span>{value.time}</span> : <></>
                        ))        
                    ))
                }
            </div>


            <h3 style={{marginTop:"16px",marginBottom:"5px"}}>PM (Evening)</h3>
            <span style={{marginTop:"10px"}}>Duration - 1 Hour</span>

            <div className="normalTime">
                {
                    StudiosBatches.MotorCity.map((item)=>(
                        item.id == "Red" &&
                        item.NormalBatch && item.NormalBatch.map((value)=>(
                            value?.batch == "evening" ?
                            <span>{value.time}</span> : <></>
                        ))        
                    ))
                }
             
            </div>

            { StudiosBatches.MotorCity.map((item)=>(
                        item?.LadiesBatch?.length > 0 && <div className="ladiesTime">
                {
                    StudiosBatches.MotorCity.map((item)=>(
                        item.id == "Red" &&
                        item.LadiesBatch && item.LadiesBatch.map((value)=>(
                            value?.batch == "evening" ?
                            <span>{value.time}</span> : <></>
                        ))        
                    ))
                }
            </div>))}
            

            <div className="differentiate">
                <div className="ladiesOnly">

                </div>
                <span style={{marginLeft:"3px",fontSize:"13px",fontWeight:"300"}}>Ladies Only</span>
            </div>
        </div>}


        {/* ===============AY Classic Time====================== */}

        {toggleTab == 1 && <div className="ClassTabContent">
            <h3 style={{marginBottom:"5px"}}>AM (Morning)</h3>
            <span>Duration - 1 Hour</span>

            <div className="normalTime">
                {
                    StudiosBatches.MotorCity.map((item)=>(
                        item.id == "AY" &&
                        item.NormalBatch && item.NormalBatch.map((value)=>(
                            value?.batch == "morning" ?
                            <span>{value.time}</span> : <></>
                        ))        
                    ))
                }
                {/* <span>9:00 AM</span>
                <span style={{ background:"#FEA3AC"}}>11:25 AM</span> */}
            </div>

            <div className="ladiesTime">
                {
                    StudiosBatches.MotorCity.map((item)=>(
                        item.id == "AY" &&
                        item.LadiesBatch && item.LadiesBatch.map((value)=>(
                            value?.batch == "morning" ?
                            <span>{value.time}</span> : <></>
                        ))        
                    ))
                }
            </div>


            <h3 style={{marginTop:"16px",marginBottom:"5px"}}>PM (Evening)</h3>
            <span style={{marginTop:"10px"}}>Duration - 1 Hour</span>


            <div className="normalTime">
                {
                    StudiosBatches.MotorCity.map((item)=>(
                        item.id == "AY" &&
                        item.NormalBatch && item.NormalBatch.map((value)=>(
                            value?.batch == "evening" ?
                            <span>{value.time}</span> : <></>
                        ))        
                    ))
                }
            </div>

            {/* {
            StudiosBatches.MotorCity.map((item)=>(
                        item.id == "AY" || item.id == "Red" && item.LadiesBatch.length > 0 ? <div className="differentiate">
                <div className="ladiesOnly"></div>
                <span style={{marginLeft:"10px",fontSize:"13px",fontWeight:"300"}}>Ladies Only</span>
            </div> : <></> ))} */}


        </div>}


        {/* =====================Meditation================================== */}

        {toggleTab == 2 && <div className="ClassTabContent">

            <h3 style={{marginTop:"16px",marginBottom:"5px"}}>PM (Evening)</h3>
            <span style={{marginTop:"10px"}}>Duration - 1 Hour</span>

            <div className="normalTime">
                <span>10:20 AM</span>
            </div>

        </div>}


        {/* ========================More================================ */}
{/* 
        {toggleTab == 3 && <div className="ClassTabContent">
           <h4>Personal Training,SuperFAST & Corporate Training <span className='moreSpan'>programs timing can be picked according
            to one's flexibility</span> 
           </h4>
        </div>} */}


        </div>

       
    </div>
  )
}

export default MotorCityLocation