import React from 'react'
import "./ebookDownload.css"

const EbookDownload = ({setEbookPopUp}) => {


    const handleEbookPopup = () => {
        setEbookPopUp(true)
    }

  return (
    <div className='GetEbook'>
        <div className="getEbookContainer">
            <div className="ebookBanner">
                <img src="/assets/ebook.png" alt="" />
            </div>

            <div className="getEbookText">
                <h1>Free E-book</h1>
                <h4>Still not sure? While you decide, we would like to offer you a free ebook - SNACK AND LOSE WEIGHT! 30 guilt-free recipes for healthy living.</h4>
                <button onClick={handleEbookPopup}>Get your E-book</button>
            </div>
        </div>

    </div>
  )
}

export default EbookDownload