import React, { useState } from 'react'
import "./specialOffers.css"
import moment from "moment/moment";
import axios from 'axios';
import { useEffect } from 'react';

const SpecialOffers = () => {

  const [offerDate,setOfferDate] = useState(null)

  const handleDate = () => {
    
    const currentDate = moment()
		const isoFormat = moment(offerDate * 1000).format()
		// console.log(moment(isoFormat).isBefore(currentDate))


		if(offerDate && moment(isoFormat).isAfter(currentDate)){
			return moment(offerDate * 1000).format('Do MMMM YYYY')
		}
		else if(offerDate && moment(isoFormat).isBefore(currentDate)){
			return moment().endOf('month').format('Do MMMM YYYY');
		}
		
		else if(offerDate && moment(offerDate * 1000).format('Do MMMM YYYY') !== moment().endOf('month').format('Do MMMM YYYY') ){
			return moment(offerDate * 1000).format('Do MMMM YYYY')
		}
    
		return moment().endOf('month').format('Do MMMM YYYY');
	}


  useEffect(() => {
	  
		axios.get("https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/offerDates")
		.then((res) => {
			console.log("Offer Dates",res)
			const studioDate = res.data.filter((item) => {
				return item?.docId == "studio"
			})
			console.log(studioDate)
			setOfferDate(studioDate[0]?.offerDate?._seconds)
		})
		.catch((err) => {
			console.log(err)
			
		})

	}, [])

  return (
    <div className='SpecialOffers'>
        <div className="specialOfferHeader">
            <h2>Special Offer</h2>
            <p>Offer ends at {handleDate()}. Purchase now and Start anytime!</p>

            <div className="offersContainer">
                <div className="offers">
                3 Months Package + Get 1 Month Free*
                </div>

                <div className="offers offers2">
                6 Months Package + Get 2 Months Free*
                </div>

                <div className="offers">
                1 Year Package + Get 3 Months Free*
                </div>
            </div>
        </div>
    </div>
  )
}

export default SpecialOffers