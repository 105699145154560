import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./OurTeacherMobile.css";
import { v4 as uuidv4 } from "uuid";
// import required modules
import { Pagination } from "swiper";
import ReactPlayer from "react-player/lazy";
import axios from "axios";
import ReactPlayerComp from "../../ReactPlayerComp/ReactPlayerComp";

export default function OurTeacherMobile() {
	const pagination = {
		el: ".my-custom-pagination-div-teacher",
		clickable: true,
		renderBullet: function (index, className) {
			return '<span class="' + className + '">' + "</span>";
		},
	};
	const [teacher, setTeacher] = useState([]);
	useEffect(() => {
		axios
			.get("teacher.json")
			.then((res) => setTeacher(res.data))
			.catch((err) => console.log(err));
	}, []);
	return (
		<>
			<Swiper
				pagination={pagination}
				modules={[Pagination]}
				className='mySwiper'>
				{teacher.map((item, ind) => {
					return (
						<SwiperSlide>
							<div className='col-12 col-sm-4 d-flex justify-content-center'>
								<ReactPlayerComp
									url={item.url}
									thumb={item.thumb}
									uid={uuidv4()}
								/>
							</div>
						</SwiperSlide>
					);
				})}
				{/* <SwiperSlide>
					<div className='col-12 col-sm-4 d-flex justify-content-center'>
						<ReactPlayer
							light={"assets/thumbnail/teacher1.png"}
							playing={true}
							className='ReactPlayer '
							controls
							playsInline // Disable download button
							config={{
								file: {
									attributes: { controlsList: "nodownload" },
								},
							}}
							// Disable right click
							onContextMenu={(e) => e.preventDefault()}
							width={"100%"}
							height={"100%"}
							playIcon={
								<img
									src='assets/play.png'
									alt=''
									style={{ width: "40px", height: "40px" }}
								/>
							}
							url='https://artisticyoga.com/uploads/videos/red/testimonial/Rahul.mp4'
						/>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='col-12 col-sm-4 d-flex justify-content-center'>
						<ReactPlayer
							light={"assets/thumbnail/teacher2.png"}
							playing={true}
							className='ReactPlayer '
							controls
							playsInline // Disable download button
							config={{
								file: {
									attributes: { controlsList: "nodownload" },
								},
							}}
							// Disable right click
							onContextMenu={(e) => e.preventDefault()}
							width={"100%"}
							height={"100%"}
							playIcon={
								<img
									src='assets/play.png'
									alt=''
									style={{ width: "40px", height: "40px" }}
								/>
							}
							url='https://artisticyoga.com/uploads/videos/red/testimonial/Gurpreet.mp4'
						/>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='col-12 col-sm-4 d-flex justify-content-center'>
						<ReactPlayer
							light={"assets/thumbnail/teacher3.png"}
							playing={true}
							className='ReactPlayer '
							controls
							playsInline // Disable download button
							config={{
								file: {
									attributes: { controlsList: "nodownload" },
								},
							}}
							// Disable right click
							onContextMenu={(e) => e.preventDefault()}
							width={"100%"}
							height={"100%"}
							playIcon={
								<img
									src='assets/play.png'
									alt=''
									style={{ width: "40px", height: "40px" }}
								/>
							}
							url='https://artisticyoga.com/uploads/videos/red/testimonial/Rakshita.mp4'
						/>
					</div>
				</SwiperSlide> */}
			</Swiper>
			<div class='my-custom-pagination-div-teacher text-center' />
		</>
	);
}
