import React from "react";
import "./WhyRed.css";
export default function WhyRed() {
	return (
		<div id='whyred' className='whyred px-3'>
			<div  className='d-flex  align-items-center justify-content-center whyRedTittle'>
				<span className='whyredHeader'>Why Artistic Yoga</span>
			</div>
			<div className='position-relative whyredImage'>
				<img
					src='assets/WhyRED.png'
					alt=''
					className='img-fluid d-none d-sm-block'
				/>
				<img
					src='assets/whyRed3.svg'
					alt=''
					className='img-fluid d-sm-none d-block'
					style={{width:"100%",height:"auto"}}
				/>
			</div>
		</div>
	);
}
