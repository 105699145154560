import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
export default function Footer() {
	return (
		<div className='Footer'>
			<div className='d-sm-block d-md-flex  align-items-center justify-content-center'>
				<div className='d-flex align-items-center justify-content-center'>

					<span className='ms-3' id="copyright">© 2023 Copyright - Artistic Yoga</span>
				</div>
				<div className="d-flex justify-content-center privacyPolicy">
					<div className='ms-2 '>
						<span className="d-inline-block footer_dot">
						</span>
						{/* <a rel="noreferrer" target="_blank" href="https://artisticyogaikriya.com/offers/assets/app/doc/PRIVACY_POLICY.PDF" className="book-now">
							<span className="" style={{ color: "rgb(255, 255, 255)", textDecoration: "underline" }}>Privacy policy
							</span>
						</a> */}
						<Link onClick={() => {
							window.scrollTo({
								top: 0,
								behavior: "smooth",
							})
						}} to="privacy-policy"><span className="" style={{ color: "rgb(255, 255, 255)", textDecoration: "underline" }}>Privacy policy
							</span></Link>
					</div>
					<div className='ms-3 '>
						<span className="d-inline-block footer_dot"></span>
						{/* <a rel="noreferrer" target="_blank" href="https://artisticyogaikriya.com/offers/assets/app/doc/TERMS_AND_CONDITIONS.PDF" className="book-now">
							<span className="" style={{ color: "rgb(255, 255, 255)", textDecoration: "underline" }}>Terms of use</span>
						</a> */}
						<Link onClick={() => {
							window.scrollTo({
								top: 0,
								behavior: "smooth",
							})
						}} to="terms-conditions"><span className="" style={{ color: "rgb(255, 255, 255)", textDecoration: "underline" }}>Terms of use</span></Link>
					</div>
				</div>
			</div>
		</div>


	);
}
