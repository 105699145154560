import React from "react";
import "./HourDayComparism.css";
export default function HourDayComparism() {
	return (
		<div id='oneHourRed' className='HourDayComparism'>
			<div className='d-block '>
				<img
					src='assets/oneHourAy.svg'
					className='img-fluid d-none d-sm-block'
					alt=''
					srcset=''
					style={{width:"100%",height:"auto"}}
				/>
				<img
					// width={"100%"}
					src='assets/oneHourAy.svg'
					className='img-fluid d-sm-none d-block'
					alt=''
					srcset=''
					style={{width:"100%",height:"auto"}}
				/>
			</div>
			{/* <div className=' d-sm-block d-none hourDaysCmp position-relative'>
				<div
					className='position-absolute yogaImage'
					style={{ zIndex: "3" }}>
					<img src='assets/yoga.svg' alt='' className='img-fluid' />
				</div>
				<div className=' d-flex align-items-center justify-content-between'>
					<div
						className='col left position-relative'
						style={{ zIndex: "1" }}>
						<div
							className='position-absolute'
							style={{
								left: "50%",
								top: "-4%",
								transform: "translate(-50%,4%)",
							}}>
							<img
								src='assets/hour.svg'
								alt=''
								className='img-fluid'
							/>
						</div>
						<div className='d-flex align-items-center p-3 my-4'>
							<div className='outerbox'>
								<div className='innerbox'>
									<img
										src='assets/warmup.svg'
										alt=''
										className='img-fluid'
									/>
								</div>
							</div>
							<div className='pros'>
								Step-by-step process of warming up & <br />{" "}
								stretching out
							</div>
						</div>
						<div className='d-flex align-items-center p-3 my-4'>
							<div className='outerbox'>
								<div className='innerbox'>
									<img
										src='assets/heartRate.svg'
										alt=''
										className='img-fluid'
									/>
								</div>
							</div>
							<div className='pros'>
								Bringing the heart rate up with <br /> cardio
								through yoga
							</div>
						</div>
						<div className='d-flex align-items-center p-3 my-4'>
							<div className='outerbox'>
								<div className='innerbox'>
									<img
										src='assets/muscles.svg'
										alt=''
										className='img-fluid'
									/>
								</div>
							</div>
							<div className='pros'>
								Toning and strenthening specific body <br />{" "}
								parts to ensure maximum results
							</div>
						</div>
						<div className='d-flex align-items-center p-3 my-4'>
							<div className='outerbox'>
								<div className='innerbox'>
									<img
										src='assets/lotus.svg'
										alt=''
										className='img-fluid'
									/>
								</div>
							</div>
							<div className='pros'>
								Breathing and meditation in order to <br /> calm
								the mind and relax the system
							</div>
						</div>
					</div>
					<div
						className='col right position-relative'
						style={{ zIndex: "1" }}>
						<div
							className='position-absolute'
							style={{
								left: "50%",
								top: "-4%",
								transform: "translate(-50%,4%)",
							}}>
							<img
								src='assets/day.svg'
								alt=''
								className='img-fluid'
							/>
						</div>
						<div className='d-flex align-items-center p-3 my-4'>
							<div className='outerbox'>
								<div className='innerbox'>
									<img
										src='assets/warmup.svg'
										alt=''
										className='img-fluid'
									/>
								</div>
							</div>
							<div className='pros'>
								Step-by-step process of warming up & <br />{" "}
								stretching out
							</div>
						</div>
						<div className='d-flex align-items-center p-3 my-4'>
							<div className='outerbox'>
								<div className='innerbox'>
									<img
										src='assets/heartRate.svg'
										alt=''
										className='img-fluid'
									/>
								</div>
							</div>
							<div className='pros'>
								Bringing the heart rate up with <br /> cardio
								through yoga
							</div>
						</div>
						<div className='d-flex align-items-center p-3 my-4'>
							<div className='outerbox'>
								<div className='innerbox'>
									<img
										src='assets/muscles.svg'
										alt=''
										className='img-fluid'
									/>
								</div>
							</div>
							<div className='pros'>
								Toning and strenthening specific body <br />{" "}
								parts to ensure maximum results
							</div>
						</div>
						<div className='d-flex align-items-center p-3 my-4'>
							<div className='outerbox'>
								<div className='innerbox'>
									<img
										src='assets/lotus.svg'
										alt=''
										className='img-fluid'
									/>
								</div>
							</div>
							<div className='pros'>
								Breathing and meditation in order to <br /> calm
								the mind and relax the system
							</div>
						</div>
					</div>
				</div>
			</div> */}
		</div>
	);
}
