import React from "react";
import "./JoinAdd.css";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function JoinAdd({ modalHandler }) {



const analytics = getAnalytics();

const handleEvent = () => {
	console.log("Button Clicked Book")
	logEvent(analytics, 'Book a free trial button: Studio Page');
}




	return (
		<>
			<div className='joinAd flex-column '>
				{/* <div className='NeverBefore d-flex align-items-center justify-content-center'>
					Never Before Offer
				</div> */}
				<div
					style={{ cursor: "pointer" }}
					onClick={() => modalHandler(true)}>
					<img
						src='assets/bookNowBtn.svg'
						alt=''
						className='bookNowWeb img-fluid d-none d-sm-inline-block d-md-none d-lg-inline-block' />
					{/* <img
						width="257px"
						height='48px'
						src='assets/bookNowBtn.svg'
						alt=''
						className='d-sm-none d-md-inline-block d-inline-block d-lg-none'
					/> */}
					<button onClick={()=>handleEvent()} className="respBookNow  d-sm-none d-md-inline-block d-inline-block d-lg-none">
						Book A Free Trial Class Today
					</button>

					{/* Join Today for just{" "}
					<span className='strikethrough'>350</span>{" "}
					<span style={{ fontSize: "28px" }}>200 </span> AED */}

				</div>
				{/* <p className='firstMonthOff text-center'>
				*Offer ends at 31st December, 2022

				</p> */}

			</div>
		</>
	);
}
