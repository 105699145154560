import React, { useEffect, useState } from "react";
import OurTeacherMobile from "./OurTeacherMobile/OurTeacherMobile";
import "./OurTeachers.css";
import ReactPlayer from "react-player/lazy";
import axios from "axios";
import ReactPlayerComp from "../ReactPlayerComp/ReactPlayerComp";
import { v4 as uuidv4 } from "uuid";
export default function OurTeachers() {
	const [teacher, setTeacher] = useState([]);
	useEffect(() => {
		axios
			.get("teacher.json")
			.then((res) => setTeacher(res.data))
			.catch((err) => console.log(err));
	}, []);
	return (
		<div className='teacher '>
			<h1 className='teacherHeader d-flex align-items-center justify-content-center '>
				Our Teachers
			</h1>
			<div className='teachersVideo d-none d-sm-flex  '>
				{teacher.map((item, ind) => {
					return (
						<div className='col-12 col-sm-4 d-flex align-items-center justify-content-center  p-2'>
							<ReactPlayerComp
								url={item.url}
								thumb={item.thumb}
								uid={uuidv4()}
							/>
						</div>
					);
				})}
			</div>

			<div className='d-sm-none d-block'>
				<OurTeacherMobile />
			</div>
			<div className='stats '>
				<div className='stateBox'>
					<div className='logo'>
						<img
							src='assets/user.svg'
							alt=''
							className='img-fluid d-none d-sm-inline-block'
						/>
						<img
							src='assets/userMobile.svg'
							alt=''
							className='img-fluid d-sm-none d-inline-block'
						/>
					</div>
					<div className='number'>850</div>
					<div className='brief'>No of Teachers</div>
				</div>
				<div className='stateBox'>
					<div className='logo'>
						<img
							src='assets/globe.svg'
							alt=''
							className='img-fluid d-none d-sm-inline-block'
						/>
						<img
							src='assets/globeMobile.svg'
							alt=''
							className='img-fluid d-sm-none d-inline-block'
						/>
					</div>
					<div className='number'>13</div>
					<div className='brief'>No of Countries</div>
				</div>
				<div className='stateBox'>
					<div className='logo'>
						<img
							src='assets/location.svg'
							alt=''
							className='img-fluid d-none d-sm-inline-block'
						/>
						<img
							src='assets/locationMobile.svg'
							alt=''
							className='img-fluid d-sm-none d-inline-block'
						/>
					</div>
					<div className='number'>473</div>
					<div className='brief'>No of Centres</div>
				</div>
			</div>
		</div>
	);
}
